P, TD, LI, DD, FONT.MENUCHOICE, A.MENUCHOICE {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
}

.gpf-content {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    &.site-prose > header + .site-text-intro {
        font-size: 1.25rem;
        line-height: 1.6;
        max-width: 72ex;
        font-weight: 300;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }
}

.gpf-logo .usa-footer__logo-img {
    margin-top: 3px;
    max-height: 40px;
    max-width: 100%;
}

.img-round {
    border-radius: 50%;
}

.main-content {
    right: 0;
    bottom: 0;
    display: inline-block;
    margin-top: 1rem;
    position: relative;
    width: 100%;
}

.main-content-no-side-nav {
    right: 0;
    bottom: 0;
    display: inline-block;
    margin-top: 2rem;
    width: 100%;
    min-height: calc(100vh - 300px);
}

.site-page-title {
    margin-top: 1rem;
}

.site-sidenav.usa-sidenav {
    font-size: .94rem;
    line-height: 1.4;
    background-color: #fff;
    border: 2px solid rgba(0,0,0,.1);
    border-radius: 0.25rem;
    padding: 0;

    a {
        color: #1c5e86;
        display: block !important;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .usa-current,
    .SELECTED {
        color: #1c5e86;
        position: static;

        &:after {
            border-radius: 0;
            background-color: #1c5e86;
            bottom: -1px;
            left: -2px;
            top: 0;
            z-index: 100;
        }
    }

    .usa-sidenav__item,
    .MENUCHOICE {
        line-height: 1.4;
        position: relative;
    }

    .usa-sidenav__sublist {
        font-size: .81rem;

        .usa-current,
        .SELECTED {
            color: #1c5e86;
        }
    }

}

.usa-sidenav__sublist {
    a:not(.usa-button) {
        display: block !important;
    }
}

.usa-banner__button {
    display: flex;

    &:after {
        right: -18px;
    }

}

.usa-breadcrumb__link:active,  .usa-breadcrumb__link:visited {
    color:#005ea2;
    text-decoration:none;
}

.usa-breadcrumb__link:hover {
    color:#0050d8;
    text-decoration:underline;
}

.usa-button {
    background-color: #1c5e86;
}

.usa-checkbox__input:checked +input + [class*=__label]::before {
    background-color: #1c5e86;
    box-shadow: 0 0 0 2px #1c5e86;
}

.usa-checkbox__input:checked + input + [class*=__label]::before,
.usa-checkbox__input:checked:disabled + input +[class*=__label]::before {
    background-position: center center;
    background-size: 0.75rem auto;
}

.usa-checkbox__input:checked + input +[class*=__label]::before,
.usa-checkbox__input:checked:disabled + input +[class*=__label]::before {
    background-image: url(../img/correct8.svg),linear-gradient(transparent,transparent);
    background-repeat: no-repeat;
}

.usa-prose .usa-collection__item {
    max-width: none;
}

.usa-header--extended .usa-logo .logo-img {
    display: flex;
    max-width: 15rem;
}

.usa-hero.nci {
    background-image: url(../img/custom/hero_nci.png);
}
.usa-form a:visited {

}
.usa-form a {color:#005ea2;text-decoration:none}
.usa-form a:hover {color:#005ea2;text-decoration:underline}
.usa-form a:visited {color:#005ea2;text-decoration:none}

.usa-footer {
    clear: both;
}

.usa-footer__primary-container {
    padding-left: 0;
    padding-right: 0;
}

.usa-identifier {
    background-color: #1c5e86;
    background-color: #164e71;

    .usa-identifier__identity-domain,
    .usa-icon-list--base-lighter .usa-icon-list__icon,
    .usa-identifier__identity-disclaimer a,
    .usa-identifier__identity-disclaimer a:visited,
    .usa-identifier__section--usagov a,
    .usa-identifier__required-link {
        color: #FFFFFF;
    }

}

.usa-link-parent > a {
    color: #005ea2;
}

.usa-link {color:#005ea2;text-decoration:none}
.usa-link:hover {color:#005ea2;text-decoration:underline}
.usa-link:visited {color:#005ea2;text-decoration:none}


.usa-logo .logo-img img {
    vertical-align: top;
}

.usa-media-block__img {
    height: 72px;
    width: 72px;
}

.usa-nav__submenu {
    &.right-just {
         width: 10rem;
    }

    .usa-nav__submenu-item a {
        display: inline-block;
        padding-left: 4px;
        width: 100%;

        &:hover {
            background-color: #20558a;
            padding-left: 4px;
            text-decoration: none;
        }
    }
}

.usa-prose > p {
    max-width: inherit !important;
}

.usa-radio__input:checked+[class*=__label]::before {
    background-color: #1c5e86;
    box-shadow: 0 0 0 2px #1c5e86, inset 0 0 0 2px #fff;
}

.usa-search [type=button] {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 2rem;
    margin: 0;
    padding: 0;
    width: 3rem;
}

.usa-search--small [type=button] {
    padding-left:0.75rem;
    padding-right:0.75rem;
    min-width:3rem;
}

.usa-section--dark a {
    color:#ffbc78;
    text-decoration:none;
}

.usa-section--dark a:hover {
    color:#ffbc78;
    text-decoration:underline;
}

.usa-section--dark a:visited {
    color:#ffbc78;
    text-decoration:none;
}


@media all and (min-width: 30em){
    .usa-search [type=button]{
        padding-left:1rem;
        padding-right:1rem;
        width:auto;
    }
}

@media (min-width: 40em) {
    .usa-footer__primary-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .usa-section {
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
    }

    .usa-hero__callout {
        max-width: 30rem;
    }
}

@media (max-width: 63.99em) {
    .usa-nav {
        .gpf-logo {
            background-color: #164e71;
            margin: 0 0 0 -16px;
            padding: 10px 18px 4px 14px;
        }
    }
}

@media (min-width: 64em) {
    .gpf-logo {
        display: flex;

        .usa-footer__logo-img {
            min-width: 400px;
        }
    }
    .main-content {
        min-height: calc(100vh - 300px);
        width: calc(100% - 15rem);
    }

    .sidenav {
        display: block;
        float: left;
        margin-bottom: 3rem;
        margin-top: 2rem;
        padding-right: 0;
        padding-left: 2rem;
        width: 15rem;
    }

    .usa-header .logo-img {
        display: flex;
        min-height: 45px;
    }

    .usa-header--extended .usa-navbar {
        min-height: 80px;
    }

    .usa-header--extended .usa-logo .logo-img {
        max-width: 100%;
    }

    [href^=http]:not([href*="discover.nci.nih.gov"]):not([href*="ccr.cancer.gov"]) {
        display: inline-block;
        padding-right: 1.75ex;
        position: relative;

        &::after {
            background: url(../img/usa-icons/launch.svg) no-repeat center/contain;
            width: 1.75ex;
            background-position: center;
            content: "";
            display: inline-block;
            height: 1.75ex;
            margin-left: 2px;
            margin-top: .7ex;
            position: absolute;

            background: 0 0;
            background-color: currentColor;
            -webkit-mask: url(../img/usa-icons/launch.svg) no-repeat center/contain;
            mask: url(../img/usa-icons/launch.svg) no-repeat center/contain;
        }
    }

    .nci-content header > h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .site-subheading {
        margin-bottom: 0;
        clear: both;
        font-family: Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
        line-height: 1.1;
        font-weight: 400;
        text-transform: uppercase;
        font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
        font-size: .81rem;
        color: rgba(0,0,0,.6);
        letter-spacing: .025em;
        margin-bottom: 0.5rem;
        margin-top: 0;
    }

    .usa-graphic-list {
        &.stacked {

            .usa-media-block {
                flex-direction: column;
            }

            .usa-media-block__body {
                flex: 0 0 auto;
                width: 100%;

                > :first-child {
                    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
                    font-size: 1.26rem;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .usa-media-block__img {
                margin-bottom: 1rem;
                margin-right: 1.25rem;
                max-width: 4.5rem;
            }
        }
    }

    .usa-header--extended {
        .usa-nav {
            background-color: #1c5e86;
            border-bottom: 1px solid rgba(0,0,0,0.1);

            .usa-nav__primary-item {
                a, button {
                    color: #FFFFFF;

                    &:hover {
                        background-color: #00314c;
                    }
                }

                > .usa-current::after {
                    background-color: #FFFFFF;
                }
            }


        }

        .usa-nav__primary-item {
            > .usa-nav__link:hover::after,
            >.usa-current::after {
                background-color: #FFFFFF;
            }
        }

        .usa-logo {
            margin: 20px 10px 12px 0;

            img {
                max-height: 41px;
            }
        }

    }

    .usa-media-block__img {
        &.medium {
            height: 70px;
        }
    }

    .usa-nav__primary {
        button[aria-expanded=false],
        button[aria-expanded=false]:hover,
        button[aria-expanded=true] {
            background-image: url(../img/custom/angle-arrow-down-white.svg),linear-gradient(transparent,transparent);
            background-position: right 0.85rem top 50%;
            background-size: 10px;
        }

        button[aria-expanded=true] {
            background-color: #00314c;
            background-image: url(../img/custom/angle-arrow-up-white.svg),linear-gradient(transparent,transparent);
        }
    }

    .usa-section--dark {
        a.alt {
            color: #00bde3;
        }
        p.blurb {
            margin: .25rem 0;
        }
    }

    .usa-nav__submenu {
        background-color: #00314c;
    }

    .grid-container,
    .usa-header--extended .usa-nav__inner,
    .usa-header--extended .usa-navbar,
    .usa-banner__content,
    .usa-banner__guidance,
    .usa-banner__inner,
    .usa-footer__nav,
    .usa-footer > .grid-container,
    .usa-footer__primary-container,
    .usa-footer__secondary-section > .grid-container,
    .usa-identifier__container {
        max-width: inherit;
    }

    .app .usa-nav__secondary {
        bottom: 4rem;
    }

}

@media all and (min-width: 85em) {
    .usa-header--extended .usa-nav__inner,
    .usa-header--extended .usa-navbar,
    .usa-banner__content,
    .usa-banner__guidance,
    .usa-banner__inner {
        max-width: inherit;
    }

    .main-content-landing-page .grid-container,
    .usa-footer>.grid-container,
    .usa-footer__nav,
    .usa-footer__primary-container,
    .usa-footer__secondary-section > .grid-container,
    .usa-identifier__container {
        max-width: 85em;
    }

}
@media (max-width: 1023px) {
    .main-content {
        margin-top:0;
    }
}

@media all and (min-width: 1440px) {
    .usa-identifier__section--required-links .usa-identifier__container {
        padding-left: 0;
        padding-right: 0;
        max-width: 86em;
    }
}
